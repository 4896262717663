<template>
  <v-row no-gutters>
    <v-col class="mt-8" sm="8" md="5" cols="12">
      <span class="ml-2"> {{ $t("notifications") }} </span>
      <v-checkbox
          class="my-0 py-0"
          v-model="syncProblemsInfo"
          :label="$t('notificationInfo.syncProblemsInfo')"
          @change="syncProblemsInfoChanged"
      ></v-checkbox>
    </v-col>
    <v-col sm="4" md="7" cols="0"></v-col>
    <v-snackbar right top app color="success" v-model="notificationsChangedSuccessfully">
      {{ $t("notificationSnackbars.notificationsChangedSuccessfully") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="notificationsChangedSuccessfully = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="notificationsChangedError">
      {{ $t("notificationSnackbars.notificationsChangedError") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="notificationsChangedError = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import {patchUser} from "../../../services/backendService";

export default {
  data() {
    return {
      syncProblemsInfo: false,

      notificationsChangedSuccessfully: false,
      notificationsChangedError: false,
    };
  },
  methods: {
    async syncProblemsInfoChanged() {
      const bodyToSend = {
        syncProblemsInfo: this.syncProblemsInfo,
      };
      const response = await patchUser(bodyToSend);
      if (!response) {
        this.notificationsChangedError = true;
      } else {
        this.notificationsChangedSuccessfully = true;
        this.$store.dispatch("changeUserNotificationsSyncProblemsInfo", this.syncProblemsInfo);
      }
    },
  },
  created() {
    this.syncProblemsInfo = this.$store.state.user.notifications.syncProblemsInfo;
  },
};
</script>

<style scoped></style>
